import { useRef, useEffect, useCallback } from 'react';

export default function useSwipe({ handleRightSwipe = () => {},
    handleLeftSwipe = () => {},
    handleUpwardsSwipe = () => {},
    handleDownwardsSwipe = () => {},
}) {
    const ref = useRef<HTMLDivElement | null>(null);

    // Stick this in a ref so it doesn't update on rerender
    const swipePosition = useRef({
        swipeStartX: 0,
        swipeStartY: 0,
    });

    const handleTouchStart = useCallback(e => {
        swipePosition.current.swipeStartX = e.targetTouches[0].clientX;
        swipePosition.current.swipeStartY = e.targetTouches[0].clientY;
    }, []);

    const handleTouchMove = useCallback(e => {
        if (!swipePosition.current.swipeStartX || !swipePosition.current.swipeStartY) {
            return;
        }

        const xUp = e.touches[0].clientX;
        const yUp = e.touches[0].clientY;

        const xDiff = swipePosition.current.swipeStartX - xUp;
        const yDiff = swipePosition.current.swipeStartY - yUp;

        if (Math.abs(xDiff) > Math.abs(yDiff)) { /* most significant */
            if (xDiff > 0) {
                /* left swipe */
                handleLeftSwipe();
            } else {
                /* right swipe */
                handleRightSwipe();
            }
        } else if (yDiff > 0) {
            /* up swipe */
            handleUpwardsSwipe();
        } else {
            /* down swipe */
            handleDownwardsSwipe();
        }
        /* reset values */
        swipePosition.current.swipeStartX = 0;
        swipePosition.current.swipeStartY = 0;
    }, [handleLeftSwipe, handleRightSwipe, handleUpwardsSwipe, handleDownwardsSwipe]);

    useEffect(() => {
        if (ref.current) {
            ref.current.addEventListener('touchstart', handleTouchStart);
            ref.current.addEventListener('touchmove', handleTouchMove);
        }

        return () => {
            if (ref.current) {
                ref.current.removeEventListener('touchstart', handleTouchStart);
                ref.current.removeEventListener('touchmove', handleTouchMove);
            }
        }
    }, [handleTouchStart, handleTouchMove]);

    return ref;
}
